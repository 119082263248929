export const getCookieByName = (cookieName: string): string | null => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookies) {
    const [name, ...value] = cookie.split('=');
    if (name === cookieName) {
      return decodeURIComponent(value.join('='));
    }
  }
  return null;
};
