'use client';
import { ComponentPropsWithoutRef, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

type CollapsibleProps = ComponentPropsWithoutRef<'div'> & {
  btnOpen: ReactNode;
  btnClose: ReactNode;
  'data-cy'?: string;
};

const Collapsible = ({ btnOpen, btnClose, children, ...rest }: CollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const searchParams = useSearchParams();

  const handleCollapse = () => setIsOpen(!isOpen);

  const collapse = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    collapse();
  }, [collapse, searchParams]);

  return (
    <div {...rest}>
      <button aria-label={isOpen ? 'Chiudi' : 'Apri'} className="w-full" onClick={handleCollapse}>
        {isOpen ? btnClose : btnOpen}
      </button>
      {isOpen && <div className="bg-inherit">{children}</div>}
    </div>
  );
};

export default Collapsible;
