import dayjs from 'dayjs';
import localeDataPlugin from 'dayjs/plugin/localeData';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/it';

dayjs.extend(localeDataPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(isTomorrowPlugin);

dayjs.locale('it');

const fromTimestamp = (timestamp?: number) => (!!timestamp ? dayjs(timestamp) : dayjs());

export const today = () => fromTimestamp().valueOf();
export const isToday = (timestamp: number) => fromTimestamp(timestamp).isToday();
export const isTomorrow = (timestamp: number) => fromTimestamp(timestamp).isTomorrow();
export const isMorning = (timestamp: number) => fromTimestamp(timestamp).hour() < 13;
export const areSameTimestamps = (timestamp1: number, timestamp2: number) => fromTimestamp(timestamp1).isSame(fromTimestamp(timestamp2));
export const areSameDayTimestamps = (timestamp1: number, timestamp2: number) =>
  fromTimestamp(timestamp1).isSame(fromTimestamp(timestamp2), 'day');
export const addDays = (days: number, timestamp?: number): number =>
  timestamp ? fromTimestamp(timestamp).add(days, 'day').valueOf() : fromTimestamp().add(days, 'days').valueOf();
export const parseDate = (timestamp: number) => {
  const formatted = fromTimestamp(timestamp).format('dddd DD MMMM YYYY HH:mm').split(' ');
  return {
    weekday: formatted[0].charAt(0).toUpperCase() + formatted[0].slice(1),
    weekdayShort: formatted[0].charAt(0).toUpperCase() + formatted[0].slice(1, 3),
    month: formatted[2].charAt(0).toUpperCase() + formatted[2].slice(1),
    monthShort: formatted[2].charAt(0).toUpperCase() + formatted[2].slice(1, 3),
    day: formatted[1],
    year: formatted[3],
    time: formatted[4],
  };
};
export const parseToNumericDate = (timestamp: number) => {
  return fromTimestamp(timestamp).format('DD/MM/YYYY');
};
export const getYearsDiffFromNow = (timestamp: number) => {
  return dayjs().year() - fromTimestamp(timestamp).year();
};
